import { mapMutations } from 'vuex';
import CommonHeader from '@/components/common/CommonHeader';
import PayslipListChild from '@/components/payslipList/PayslipListChild';
import PayslipSecrecy from '@/components/payslipList/PayslipSecrecy';
export default {
  name: 'PayslipList',
  components: {
    PayslipListChild,
    CommonHeader,
    PayslipSecrecy
  },
  beforeRouteEnter(to, from, next) {
    // 判断是否来自detail
    if (from.path === '/payslip-detail') {
      next(vm => {
        vm.fromPath = from.path;
      });
    }
    next();
  },
  beforeRouteLeave(to, from, next) {
    if (to.path === '/payslip-detail') {
      // 保存当前年月参数
      const params = this.$refs.payslipListChild.params;
      this.setSalaryListQuery(params);
    }
    next();
  },
  props: {
    isSubCom: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isFirstEnter: true,
      fromPath: ''
    };
  },
  methods: {
    ...mapMutations('payslip', ['setSalaryListQuery']),
    onToggleSecrecyVisibleFn(isFirst) {
      this.isFirstEnter = JSON.parse(isFirst);
      if (isFirst === 'false') {
        // 不是首次进入，请求页面数据
        if (this.fromPath === '/payslip-detail') {
          // 回显年月参数
          this.$nextTick(() => {
            this.$refs.payslipListChild.recoverYearMonthFn();
          });
        } else {
          // 清空年月参数
          this.setSalaryListQuery({});
          this.$nextTick(() => {
            this.$refs.payslipListChild.initYearFn();
          });
        }
      }
    }
  }
};