var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "payslip-list-box"
  }, [_vm.isFirstEnter ? _c("CommonHeader") : _vm._e(), !_vm.isFirstEnter ? _c("PayslipListChild", {
    ref: "payslipListChild"
  }) : _vm._e(), _vm.isFirstEnter ? _c("PayslipSecrecy", {
    attrs: {
      "target-path": "payslip-list"
    },
    on: {
      toggleSecrecyVisible: _vm.onToggleSecrecyVisibleFn
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };